import { Link, createFileRoute } from '@tanstack/react-router'

import logo from '../../assets/logo-white.svg';
import logoEvent from '../../assets/logo-event.png?format=webp';
import { useQuery } from '@tanstack/react-query'
import { fetchConfig } from '../../api';

export const Route = createFileRoute('/_public_layout/')({
  component: Index,
})


function Index() {
  const query = useQuery({ queryKey: ['config'], queryFn: fetchConfig});

  return (
    <>
      <img src={logo} className='block mx-auto max-w-xs md:max-w-full my-10' />
      <h1 className='text-4xl text-center text-primary font-bold mb-10'>{query.data?.home_title}</h1>
      <h2 className="text-xl text-center text-white font-bold">{query.data?.home_location}</h2>
      <img src={logoEvent} className='block mx-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-full my-20' />
      <Link to="/dashboard" className='login-button'>Login</Link>
    </>
  )
}
