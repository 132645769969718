
import { Link } from '@tanstack/react-router';
import logo from '../assets/logo.svg';
import { HomeFilled } from '@ant-design/icons';
import { fetchConfig } from '../api';
import { useQuery } from '@tanstack/react-query';

type Props = {
  children?: React.ReactNode;
};

export function AppLayout({ children }: Props) {
  const query = useQuery({ queryKey: ['config'], queryFn: fetchConfig});

  return (
    <>
      <div className="header">
        <div className="flex gap-8 items-center">
          <img src={logo} className='block logo' />
          <div className='font-bold hidden lg:block'>{query.data?.home_location}</div>
          <Link className='ms-auto text-2xl' to="/dashboard">
            <HomeFilled />
          </Link>
        </div>
      </div>
      {children}
    </>
  )
}