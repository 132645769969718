import { queryOptions } from "@tanstack/react-query";
import { fetchAgendaDays, fetchSurvey, fetchAnsweredSurvey, fetchAnswers } from "./api";

export const agendaQueryOptions = queryOptions({
  queryKey: ['agenda_days'],
  queryFn: () => fetchAgendaDays(),
})

export const activeSurveyOptions = (userId: string | undefined) => queryOptions({
  queryKey: ['surveys'],
  queryFn: () => fetchSurvey(userId),
  refetchInterval: 10000,
})

export const answeredSurveyOptions = (userId: string | undefined) => queryOptions({
  queryKey: ['surveys', 'answered'],
  queryFn: () => fetchAnsweredSurvey(userId),
  refetchInterval: 10000,
})


export const answersSurveyOptions = queryOptions({
  queryKey: ['surveys', 'answers'],
  queryFn: () => fetchAnswers(),
  refetchInterval: 5000,
})
