import React from "react";
import { fetchConfig } from "../api";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

type Props = {
  children?: React.ReactNode;
};

export function PublicLayout({ children }: Props) {
  const query = useQuery({ queryKey: ['config'], queryFn: fetchConfig});

  return (
    <div id="public-root" className='min-h-screen'>
      <div className='max-w-5xl mx-auto h-full pt-9'>
        <div className='flex justify-center align-middle flex-col px-2'>
          { children }
          <div className='text-center my-10'>
            <p className='text-center text-white my-5'>Powered by MAD srl</p>
            <p className='text-center text-white my-5'>©{dayjs().format('YYYY')} All rights reserved</p>
            <a href={query.data?.policy_url || '#'} className='text-primary text-center hover:underline' target='_blank'>Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  )
}