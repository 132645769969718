import { Link, createFileRoute } from '@tanstack/react-router'
import { activeSurveyOptions, answeredSurveyOptions } from '../../../queryOptions'
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useAuth } from '../../../auth'
import { insertAnswer } from '../../../api'
import toast from 'react-hot-toast'

export const Route = createFileRoute('/_authed/_filled/survey')({
  loader: ({ context: { queryClient, auth } }) => {
    queryClient.ensureQueryData(activeSurveyOptions(auth.user?.id))
    queryClient.ensureQueryData(answeredSurveyOptions(auth.user?.id))
  },
  component: Survey,
})

function Survey() {
  const auth = useAuth()
  const queryClient = useQueryClient()
  const { data, error, isPending } = useSuspenseQuery(activeSurveyOptions(auth.user?.id));
  const { data: answered } = useSuspenseQuery(answeredSurveyOptions(auth.user?.id));
  console.log(data, error);

  const mutation = useMutation({
    mutationFn: insertAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['surveys'] })
      toast.success('Risposta inviata!')
    },
    onError: (e) => {
      console.error(e);
      toast.error('Si è verificato un errore, riprova più tardi')
    }
  })

  const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    try {
      const formData = new FormData(evt.currentTarget)
      const text = formData.get('text')

      mutation.mutate({
        survey: data?.id!,
        text: text?.toString()!,
        user: auth.user?.id,
      })
    } catch (error) {
      console.error('Error logging in: ', error)
      toast.error('Si è verificato un errore, riprova più tardi')
    }
  }

  if (!data) {
    return (
      <div className="max-w-3xl mx-auto my-20">
        <div className=''>
          {answered?.length ? (
            <>
              <h4 className="text-4xl md:text-6xl font-bold text-primary uppercase text-center">Grazie per il tuo voto!</h4>
              <p className='text-center mt-5'><Link to="/answers" className='text-white text-xl underline-offset-1 font-bold'>Visualizza i risultati</Link></p>
            </>
          ) : (
            <h4 className="text-4xl md:text-6xl font-bold text-primary uppercase text-center">Nessuna survey attiva</h4>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className='max-w-full md:max-w-6xl mx-auto py-5'>
      <div>
        <h4 className="text-4xl md:text-6xl font-bold text-primary uppercase text-center">{data.title}</h4>
        <h6 className="text-xl md:text-3xl font-bold text-primary uppercase text-center brightness-[80%]">{data.title_en}</h6>
      </div>
      <div className='mt-10'>
        {data.type === 'choice' && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 py-3 px-3 text-white">
            {data.options.map(opt => (
              <button
                key={opt.id}
                disabled={mutation.isPending || isPending}
                onClick={() => {
                  if (!mutation.isPending) {
                    mutation.mutate({
                      survey: data.id,
                      user: auth.user?.id,
                      text: opt.text,
                    })
                  }
                }}
                className='border-2 rounded-md text-3xl p-4 md:p-8 hover:text-primary hover:border-primary'
              >{opt.text}</button>
            ))}
          </div>
        )}
        {data.type === 'free' && (
          <form className='flex items-center justify-center flex-col gap-4' onSubmit={onFormSubmit}>
            <input name="text" required className='text-xl md:text-2xl lg:text-4xl text-white bg-transparent border-white focus:ring-primary' placeholder='La tua risposta' />
            <button disabled={mutation.isPending} className='bg-primary text-secondary px-8 py-4 font-bold uppercase rounded text-xl'>Invia</button>
          </form>
        )}
      </div>
    </div>
  )
}