import { useForm } from '@tanstack/react-form'
import { Profile } from '../types';
import DatePicker from 'react-date-picker';

type ProfileFormProps = {
  onSubmit?: ({ value }: { value: Profile }) => void
  defaultValues?: Profile
}

const DEFAULT = {
  first_name: '',
  last_name: '',
  room_type: 1,
  companion_first_name: '',
  companion_last_name: '',
  birth_place: '',
  birth_date: null,
  doc_type: "Carta d'identità",
  doc_number: '',
  doc_issue_date: null,
  doc_expiry_date: null,
  place: '',
  nationality: 'Italiana',
  in_date: null,
  out_date: null,
  food_intolerance: '',
}

export function ProfileForm({ defaultValues = DEFAULT, onSubmit }: ProfileFormProps) {
  const form = useForm({
    defaultValues,
    onSubmit,
  })
  return (
      <form
        id="profile-form"
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            form.handleSubmit()
          }}
          className='grid grid-cols-1 gap-4'
        >
          <div className='flex flex-col'>
            <form.Field
              name="first_name"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Nome</label>
                    <input
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            {/* A type-safe field component*/}
            <form.Field
              name="last_name"
              children={(field) => {
                // Avoid hasty abstractions. Render props are great!
                return (
                  <>
                    <label htmlFor={field.name}>Cognome</label>
                    <input
                      id={field.name}
                      name={field.name}
                      value={field.state.value || ''}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="in_date"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Data arrivo</label>
                    <DatePicker 
                      id={field.name}
                      name={field.name}
                      locale='it-IT'
                      value={field.state.value}
                      // @ts-ignore:next-line
                      onChange={(v: Date) => field.handleChange(v)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="out_date"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Data partenza</label>
                    <DatePicker 
                      id={field.name}
                      name={field.name}
                      locale='it-IT'
                      value={field.state.value}
                      // @ts-ignore:next-line
                      onChange={(v: Date) => field.handleChange(v)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            {/* A type-safe field component*/}
            <form.Field
              name="birth_date"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Data di nascita</label>
                    <DatePicker 
                      id={field.name}
                      name={field.name}
                      locale='it-IT'
                      value={field.state.value}
                      // @ts-ignore:next-line
                      onChange={(v: Date) => field.handleChange(v)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="birth_place"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Luogo di nascita</label>
                    <input
                      id={field.name}
                      name={field.name}
                      value={field.state.value || ''}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="doc_type"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Tipo di documento</label>
                    <select
                      id={field.name}
                      name={field.name}
                      value={field.state.value || ''}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      required
                    >
                      <option value="Carta d'identità">Carta d'identità</option>
                      <option value="Passaporto">Passaporto</option>
                    </select>
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="doc_number"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Numero del documento</label>
                    <input
                      id={field.name}
                      name={field.name}
                      value={field.state.value || ''}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="doc_issue_date"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Data emissione</label>
                    <DatePicker 
                      id={field.name}
                      name={field.name}
                      locale='it-IT'
                      value={field.state.value}
                      // @ts-ignore:next-line
                      onChange={(v: Date) => field.handleChange(v)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="doc_expiry_date"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Data scadenza</label>
                    <DatePicker 
                      id={field.name}
                      name={field.name}
                      locale='it-IT'
                      value={field.state.value}
                      // @ts-ignore:next-line
                      onChange={(v: Date) => field.handleChange(v)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="place"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Indirizzo di Residenza (Via, Numero civico; CAP, Città)</label>
                    <input
                      id={field.name}
                      name={field.name}
                      value={field.state.value || ''}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="nationality"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Nazionalità</label>
                    <input
                      id={field.name}
                      name={field.name}
                      value={field.state.value || ''}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      required
                    />
                  </>
                )
              }}
            />
          </div>
          <div className='flex flex-col'>
            <form.Field
              name="food_intolerance"
              children={(field) => {
                return (
                  <>
                    <label htmlFor={field.name}>Intolleranze alimentari</label>
                    <textarea
                      className='bg-transparent border'
                      id={field.name}
                      name={field.name}
                      value={field.state.value || ''}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                    ></textarea>
                  </>
                )
              }}
            />
          </div>
          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <button type="submit" disabled={!canSubmit} className='button bg-primary text-secondary disabled:opacity-15 mt-5'>
                {isSubmitting ? 'Salvataggio...' : 'Salva'}
              </button>
            )}
          />
        </form>
  )
}
