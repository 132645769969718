import { createFileRoute } from '@tanstack/react-router'
import { answersSurveyOptions } from '../../../queryOptions'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Chart } from "react-google-charts";
import { SurveyAnswers } from '../../../types';

function Survey({ survey_type, survey_title, answers, survey_title_en }: SurveyAnswers) {

  console.log(survey_title_en)

  if (survey_type === 'choice') {
    const data = [["Risposta", "Quantità"], ...answers.map(a => ([a.text, a.value]))]
    const options = {
      title: survey_title + '\n' + survey_title_en,
    }
    return (
      <Chart
        chartType="PieChart"
        options={options}
        data={data}
        width={"100%"}
        height={"400px"}
      />
    )
  }

  return null;
}


export const Route = createFileRoute('/_authed/_filled/answers')({
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureQueryData(answersSurveyOptions)
  },
  component: Answers,
})

function Answers() {
  const { data } = useSuspenseQuery(answersSurveyOptions);

  console.log(data);

  return (
    <div className=''>
      <div className='header mb-10'>
        <h2 className='text-5xl font-bold text-center'>Risposte</h2>
      </div>
      {data && data.length ? (
        <div className='max-w-5xl mx-auto px-5'>
          {data.map(s => <Survey key={s.survey} {...s} />)}
        </div>

      ) : (
        <h4 className='text-primary text-5xl text-center font-bold'>Non ci sono sondaggi attivi</h4>
      )}
    </div>
  )
}