import { queryOptions, useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { Outlet, createFileRoute } from '@tanstack/react-router'
import { createProfile, fetchProfile } from '../../api';
import { ProfileForm } from '../../components/profileForm';
import { useAuth } from '../../auth';
import { Profile } from '../../types';
import { PublicLayout } from '../../components/publicLayout';
import { AppLayout } from '../../components/appLayout';
import logoEvent from '../../assets/logo-event.png?format=webp';


export const profileQueryOptions = queryOptions({
  queryKey: ['profile'],
  queryFn: () => fetchProfile(),
});


export const Route = createFileRoute('/_authed/_filled')({
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(profileQueryOptions),
  component: FilledProfile,
})

function FilledProfile() {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const profileQuery = useSuspenseQuery(profileQueryOptions);
  const profile = profileQuery.data;
  const createProfileMutation = useMutation({
    mutationFn: createProfile,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['profile'] })
  })

  if (!profile) {
    return (
      <PublicLayout>
        <img src={logoEvent} className='block mx-auto max-w-lg mt-20' />
        <h2 className='text-white font-bold text-3xl my-5 text-center'>Completa le Informazioni</h2>
        <ProfileForm onSubmit={({ value }: { value: Profile }) => createProfileMutation.mutate({ ...value, id: user?.id })} />
      </PublicLayout>
    )
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  )
}