import { ArrowLeftOutlined } from '@ant-design/icons';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { Link, createFileRoute, useNavigate, useRouter } from '@tanstack/react-router'
import { fetchProfile } from '../../../api';
import { useAuth } from '../../../auth';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

export const profileQueryOptions = queryOptions({
  queryKey: ['profile'],
  queryFn: () => fetchProfile(),
});


export const Route = createFileRoute('/_authed/_filled/profile')({
  component: Profile,
  loader: ({ context: { queryClient } }) =>
  queryClient.ensureQueryData(profileQueryOptions),
})

function Profile() {
  const auth = useAuth();
  const router = useRouter();
  const navigate = useNavigate();
  const profileQuery = useSuspenseQuery(profileQueryOptions);
  const profile = profileQuery.data;

  console.log(profile)

  const logout = async () => {
    auth.signOut();
    await router.invalidate();
    toast('Ti sei scollegato con successo');
    navigate({
      to: '/',
      replace: true,
    });
  }

  return (
    <>
      <div className='header'>
        <h2 className='text-5xl font-bold text-center'>Profilo</h2>
      </div>
      <div className='max-w-full mx-2 lg:max-w-5xl md:mx-auto bg-white px-4 py-3 my-10 rounded-sm'>
        <h4 className='text-2xl font-bold'>I miei dati</h4>
        <div className='grid grid-cols-2 gap-4'>
          <div>Nome: {profile.first_name}</div>
          <div>Cognome: {profile.last_name}</div>
        </div>

        <h4 className='text-2xl font-bold my-5'>Informazioni inviate durante la registrazione</h4>
        <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
          <div>Arrivo: {dayjs(profile.in_date).format('DD/MM/YYYY')}</div>
          <div>Partenza: {dayjs(profile.out_date).format('DD/MM/YYYY')}</div>
          <div>Data di nascita: {dayjs(profile.birth_date).format('DD/MM/YYYY')}</div>
          <div>Città di nascita: {profile.birth_place}</div>
          <div>Tipo di documento: {profile.doc_type}</div>
          <div>Numero del documento: {profile.doc_number}</div>
          <div>Data di emissione: {dayjs(profile.doc_issue_date).format('DD/MM/YYYY')}</div>
          <div>Data di scadenza: {dayjs(profile.doc_expiry_date).format('DD/MM/YYYY')}</div>
          <div>Indirizzo di residenza: {profile.place}</div>
          <div>Nazionalità: {profile.nationality}</div>
        </div>

        <h4 className='text-2xl font-bold mt-5'>Scollegati</h4>
        <div className='flex justify-between mt-3'>
          <button onClick={logout} className='button bg-red-600 text-white'>Esci da MADWAVE</button>
          <Link to="/dashboard" className='button bg-primary text-secondary'><ArrowLeftOutlined /> Indietro</Link>
        </div>
      </div>
    </>
  );
}
