import { useSuspenseQuery } from '@tanstack/react-query'
import {
  createFileRoute,
} from '@tanstack/react-router'
import dayjs from 'dayjs'
import parse from 'html-react-parser';
import { Fragment } from 'react/jsx-runtime';
import { agendaQueryOptions } from '../../../queryOptions';

export const Route = createFileRoute('/_authed/_filled/agenda')({
  component: Agenda,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(agendaQueryOptions),
})

function Agenda() {
  const agendaQuery = useSuspenseQuery(agendaQueryOptions)
  const agendaDays = agendaQuery.data

  return (
    <>
      <div className="header">
        <h2 className="text-5xl font-bold text-center">Schedule</h2>
      </div>
      <div className="max-w-full mx-2 lg:max-w-5xl md:mx-auto px-4 py-10 rounded-sm">
        {
          (agendaDays || []).map((day, index) => (
            <Fragment key={day.datetime}>
              <div className='mb-4'>
                <h4 className="text-4xl font-bold text-primary uppercase">{dayjs(day.datetime).format('DD MMMM')}</h4>
                <div className="grid grid-cols-3 md:grid-cols-6 gap-8 my-3 text-white text-2xl">
                  {day.entry.map(e => (
                    <>
                      <div className='col-start-1'>
                        {e.prefix_it && (<span className='mr-2 text-sm'>{e.prefix_it}</span>)}
                        <b>{dayjs(e.datetime).format('HH:mm')}</b><br />
                        <span className='text-gray-400 text-sm'>{e.prefix_en}</span>
                      </div>
                      <div className='col-span-2 md:grid-cols-5'>{parse(e.text_it)}<br /><span className='text-gray-400 text-md'>{parse(e.text_en || '')}</span></div>
                    </>
                  ))}
                </div>
              </div>
              {index + 1 < (agendaDays?.length || 0) && <hr className='my-10' />}
            </Fragment>
          ))
        }
      </div>
    </>
  )
}
