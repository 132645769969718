import * as React from 'react'
import {
  createFileRoute,
  redirect,
  useRouterState,
} from '@tanstack/react-router'
import logoEvent from '../../assets/logo-event.png?format=webp';
import { z } from 'zod'

import { supabase } from '../../supabase';
import { fetchConfig } from '../../api';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

// eslint-disable-next-line ts/no-unnecessary-type-assertion
const fallback = '/dashboard' as const

export const Route = createFileRoute('/_public_layout/login')({
  validateSearch: z.object({
    redirect: z.string().optional().catch(''),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.auth?.user) {
      throw redirect({ to: search.redirect || fallback })
    }
  },
  component: LoginComponent,
})

function LoginComponent() {
  const isLoading = useRouterState({ select: (s) => s.isLoading })
  const navigate = Route.useNavigate()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const query = useQuery({ queryKey: ['config'], queryFn: fetchConfig});

  const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    setIsSubmitting(true)
    try {
      const data = new FormData(evt.currentTarget)
      const userField = data.get('username')

      if (!userField) return
      const email = userField.toString().toLowerCase()

      const result = await supabase.auth.signInWithOtp({
        email,
      })

      if (result.error) {
        toast.error(`Si è verificato un errore: ${result.error.message}`)
      } else {
        await navigate({ to: `/emailSent`, search: { email } })
      }
    } catch (error) {
      console.error('Error logging in: ', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const isLoggingIn = isLoading || isSubmitting

  return (
    <div className="p-2 grid gap-2 place-items-center max-w-xs lg:max-w-4xl mx-auto">
      <img src={logoEvent} className='block mx-auto max-w-xs lg:max-w-lg mt-20' />
      <p className='font-bold my-5 text-white'>{query.data?.home_location}</p>
      <form className="mt-4 w-full" onSubmit={onFormSubmit}>
        <fieldset disabled={isLoggingIn} className="w-full grid gap-2">
          <div className="grid gap-2 items-center w-full">
            <input
              id="username-input"
              name="username"
              placeholder="Inserisci la tua email"
              type="text"
              className="border border-white text-white focus:text-white active:text-white rounded-sm p-2 bg-transparent w-full"
              required
            />
          </div>
          <button
            type="submit"
            className="button bg-primary text-secondary w-[15rem] block mx-auto"
          >
            {isLoggingIn ? 'Attendi...' : 'Accedi'}
          </button>
        </fieldset>
      </form>
    </div>
  )
}
