import { createFileRoute } from "@tanstack/react-router";

import logo from "../../../assets/logo-event.png?format=webp";
import { Link } from "@tanstack/react-router";

import ProfileIcon from "../../../assets/profile.svg?react";
import ForkIcon from '../../../assets/utensils-solid.svg?react';
import PollIcon from '../../../assets/poll.svg?react';
import { ClockCircleOutlined, CommentOutlined, FireOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import { fetchConfig } from "../../../api";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

const configQueryOptions = queryOptions({
  queryKey: ["config"],
  queryFn: fetchConfig,
});

export const Route = createFileRoute("/_authed/_filled/dashboard")({
  component: Dashboard,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(configQueryOptions),
});

type Props = {
  children?: React.ReactNode;
  text: string;
  icon?: React.ReactNode | null;
  to: string;
  target?: string;
};

function Block({ icon = null, text, to, target }: Props) {
  return (
    <Link
      to={to}
      className="border-primary border rounded-sm text-white h-[8rem] grid grid-cols-4 gap-4 block-border px-2"
      target={target}
    >
      <div className="col-span-1 flex justify-center items-center">
        <span className="box">{icon}</span>
      </div>
      <div className="col-span-3 self-center flex align-middle">
        <span className="font-bold text-2xl">{text}</span>
      </div>
    </Link>
  );
}

function Dashboard() {
  const config = useSuspenseQuery(configQueryOptions);

  return (
    <div className="flex flex-col align-middle justify-center mx-auto px-2 py-2">
      <img
        src={logo}
        className="block max-w-xs md:max-w-full lg:max-w-3xl mx-auto my-10"
      />
      <h2 className="text-white text-4xl font-bold text-center">Home</h2>
      <div className="mx-auto max-w-full lg:w-[64rem] mt-10">
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
          <Block
            text="Profilo"
            to="/profile"
            icon={<Icon component={ProfileIcon} className="text-[5rem]" />}
          />
          {config.data?.agenda_public && (
            <Block
              text="Agenda"
              to="/agenda"
              icon={<ClockCircleOutlined className="text-4xl lg:text-5xl" />}
            />
          )}
          {config.data?.survey_public && (
            <Block
              text="Let’s Dance"
              to="/survey"
              icon={<FireOutlined className="text-4xl lg:text-5xl" />}
            />
          )}
          {config.data?.tableau_dinner_url && (
            <Block
              text={config.data?.tableau_dinner_text}
              target="_blank"
              to={config.data?.tableau_dinner_url}
              icon={<Icon component={ForkIcon} className="text-4xl lg:text-5xl" />}
            />
          )}
          {config.data?.tableau_activities_url && (
            <Block
              text={config.data?.tableau_activities_text}
              target="_blank"
              to={config.data?.tableau_activities_url}
              icon={<CommentOutlined className="text-4xl lg:text-5xl" />}
            />
          )}
          <Block
            text={config.data?.interaction_text}
            target="_blank"
            to={config.data?.interaction_url}
            icon={<Icon component={PollIcon} className="text-4xl lg:text-5xl" />}
          />
        </div>
      </div>
    </div>
  );
}
