import { Outlet, createFileRoute } from '@tanstack/react-router'
import { PublicLayout } from '../components/publicLayout'

export const Route = createFileRoute('/_public_layout')({
  component: Layout,
})

function Layout() {
  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  )
}