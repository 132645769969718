import toast from "react-hot-toast";
import { supabase } from "./supabase";
import { AgendaDay, Answer, Profile, Survey, SurveyAnswers } from "./types";

import dayjs from 'dayjs';

function toDate(text: string | null): Date | null {
  if (typeof text === 'string' && text != '') {
    return dayjs(text, 'YYYY-MM-DD').toDate()
  }
  return null
}


const FIELDS = [
  'in_date',
  'out_date',
  'birth_date',
  'doc_issue_date',
  'doc_expiry_date',
]


export async function fetchProfile() {
  const { data, error } = await supabase
  .from('profiles')
  .select().limit(1)

  if (error) {
    toast.error(error.message)
  }

  if (!data?.length) {
    return null;
  }

  const profile = data[0];

  for (const f of FIELDS) {
    profile[f] = toDate(profile[f]);
  }

  return profile;
}

export async function fetchConfig() {
  const { data, error } = await supabase
  .from('config')
  .select().limit(1)

  if (error) {
    toast.error(error.message)
  }

  if (!data?.length) {
    return null;
  }

  return data[0];
}

export async function createProfile(profile: Profile) {
  return supabase.from('profiles').insert(profile);
}

export async function fetchAgendaDays(): Promise<AgendaDay[] | null> {
  const { data, error } = await supabase
  .from('agenda_days')
  .select().order('datetime')

  if (error) {
    toast.error(error.message)
  }

  return data;
}

async function fetchAnswered(userId: string | undefined) {
  if (!userId) {
    return [];
  }
  // NOTE: this approach is not scalable, unless grouping by or using a rpc...
  const { data } = await supabase.from('survey_answer').select('survey').filter('user', 'eq', userId);
  return data?.map(d => d.survey)
}

export async function fetchSurvey(userId: string | undefined): Promise<Survey | null> {
  const ids = await fetchAnswered(userId);
  console.log('found ids', ids);

  let query = supabase
  .from('active_surveys')
  .select()

  if (ids && ids.length) {
    query = query.not('id', 'in', `(${ids.join(',')})`)
  }

  const { data, error } = await query.limit(1)

  if (error) {
    toast.error(error.message)
  }

  if (!data?.length) {
    return null;
  }

  return data[0];
}

export async function fetchAnsweredSurvey(userId: string | undefined): Promise<Survey[] | null> {
  const ids = await fetchAnswered(userId);

  let query = supabase
    .from('active_surveys')
    .select()

  if (ids && ids.length) {
    query = query.in('id', ids);
  }

  const { data, error } = await query;

  if (error) {
    toast.error(error.message)
  }

  if (!data?.length) {
    return null;
  }

  return data;
}

export async function fetchAnswers(): Promise<SurveyAnswers[] | null> {
  let query = supabase
    .from('survey_answer_count')
    .select()

  const { data, error } = await query;

  if (error) {
    toast.error(error.message)
  }

  if (!data?.length) {
    return null;
  }

  return data;
}


export async function insertAnswer(answer: Answer): Promise<void> {
  await supabase.from('survey_answer').insert(answer)
}