import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from '@tanstack/react-router'
import {
  QueryClientProvider,
} from '@tanstack/react-query'

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import './index.css';

import './sentry';

import * as dayjs from 'dayjs'
import 'dayjs/locale/it';
import customParse from "dayjs/plugin/customParseFormat";
dayjs.locale('it');
dayjs.extend(customParse);


// Import the generated route tree
import AuthProvider, { useAuth } from './auth';
import { queryClient } from './queryClient';
import { router } from './router';

const App = () => {
  const auth = useAuth();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} context={{ auth, queryClient }} />
    </QueryClientProvider>
  )
}

// Render the app
const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <StrictMode>
      <AuthProvider router={router}>
        <App />
      </AuthProvider>
    </StrictMode>,
  )
}
