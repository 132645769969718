import { AuthError, User } from '@supabase/supabase-js';
import { createContext, useState, useEffect, useContext, Dispatch } from 'react';
import { supabase } from './supabase';
import toast from 'react-hot-toast';
import { router } from './router';

type AuthContextType = {
  user: User | null;
  signOut: () => Promise<AuthError | null>,
  setUser: Dispatch<React.SetStateAction<User | null>>,
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  signOut: async () => null,
  setUser: () => null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

type Props = {
  children: React.ReactNode,
  router: typeof router
}

const AuthProvider = ({ children, router }: Props) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        console.log('session onAuthStateChange: ', session);
        setUser(session?.user || null);
        setLoading(false);
      }
    );
    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    router.invalidate();
  }, [user])

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setUser(null);
      return null;
    }
    toast.error(error?.message || 'Errore di autenticazione');
    return error;
  };

  return (
    <AuthContext.Provider value={{ user, signOut, setUser }}>
      {!loading ? children : `Caricamento...`}
    </AuthContext.Provider>
  );
};

export default AuthProvider;