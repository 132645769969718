import { createFileRoute, redirect, useRouterState } from '@tanstack/react-router'
import logoEvent from '../../assets/logo-event.png?format=webp';
import { useQuery } from '@tanstack/react-query';
import { fetchConfig } from '../../api';
import React from 'react';
import { supabase } from '../../supabase';
import toast from 'react-hot-toast';
import { useAuth } from '../../auth';

type SearchParams = {
  email: string,
}

export const Route = createFileRoute('/_public_layout/emailSent')({
  component: EmailSent,
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    email: (search.email as string),
  }),
  beforeLoad: ({ search, context }) => {
    if (!search.email) {
      throw redirect({ to: '/login' });
    }
    if (context.auth?.user) {
      throw redirect({ to: '/dashboard' });
    }
  }
})

function EmailSent() {
  const query = useQuery({ queryKey: ['config'], queryFn: fetchConfig});
  const { setUser } = useAuth(); 
  const isLoading = useRouterState({ select: (s) => s.isLoading })
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { email } = Route.useSearch();

  const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    setIsSubmitting(true)
    try {
      const data = new FormData(evt.currentTarget)
      const otpField = data.get('otp')

      if (!otpField) return
      const token = otpField.toString()

      const result = await supabase.auth.verifyOtp({
        token,
        email,
        type: 'email',
      })

      if (result.error) {
        toast.error(`Si è verificato un errore: ${result.error.message}`)
      } else {
        setUser(result.data.user);
        toast.success(`Accesso effettuato`);
      }
    } catch (error) {
      console.error('Error logging in: ', error)
      toast.error(`Si è verificato un errore: ${error}`)

    } finally {
      setIsSubmitting(false)
    }
  }

  const isLoggingIn = isLoading || isSubmitting;

  return (
    <div className="p-2 grid gap-2 place-items-center max-w-xs lg:max-w-4xl mx-auto text-white">
      <img src={logoEvent} className='block mx-auto max-w-full lg:max-w-lg mt-20' />
      <p className='font-bold my-5 text-center'>{query.data?.home_location}</p>
      <h3 className='font-bold text-4xl text-center'>Controlla la casella email</h3>
      <p className='text-center'>Troverai un email da parte di <span className='font-bold underline'>noreply@madwave.app</span> con un codice necessario per accedere, inseriscilo qui sotto e premi Accedi.</p>
      <p className='font-bold text-center'><u>Potrebbe essere necessario controllare anche la posta indesiderata</u></p>

      <form className="mt-4 w-full" onSubmit={onFormSubmit}>
        <fieldset disabled={isLoggingIn} className="w-full grid gap-2">
          <div className="grid gap-2 items-center w-full">
            <input
              id="otp-input"
              name="otp"
              placeholder="Inserisci il codice OTP"
              type="text"
              className="border border-white text-white focus:text-white active:text-white rounded-sm p-2 bg-transparent w-full"
              required
            />
          </div>
          <button
            type="submit"
            className="button bg-primary text-secondary w-[15rem] block mx-auto"
          >
            {isLoggingIn ? 'Attendi...' : 'Accedi'}
          </button>
        </fieldset>
      </form>
    </div>
  )
}